import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

export default function UserManual() {
  const classes = useStyles();

  const [isExpandAllEnabled, setIsExpandAllEnabled] = useState(false);
  const [isCollapseAllEnabled, setIsCollapseAllEnabled] = useState(false);

  const [sectionsExpanded, setSectionsExpanded] = useState({
    loggingIn: false,
    home: false,
    addPatientLog: false,
    addPatient: false,
    patientLogDetail: false,
    patientDetail: false,
    searchPatients: false,
    searchPatientLogs: false,
    applicationSettings: false,
  });

  const setExpandedForSection = (sectionKey, updatedExpanded) => {
    const updatedSectionsExpanded = { ...sectionsExpanded };
    updatedSectionsExpanded[sectionKey] = updatedExpanded;
    setSectionsExpanded(updatedSectionsExpanded);
  };

  const setExpandedForAllSections = (updatedExpanded) => {
    const updatedSectionsExpanded = { ...sectionsExpanded };

    for (const key in updatedSectionsExpanded) {
      if (!updatedSectionsExpanded.hasOwnProperty(key)) {
        continue;
      }

      updatedSectionsExpanded[key] = updatedExpanded;
    }

    setSectionsExpanded(updatedSectionsExpanded);
  };

  const handleExpandAllButtonClick = (event) => {
    setExpandedForAllSections(true);
  };

  const handleCollapseAllButtonClick = (event) => {
    setExpandedForAllSections(false);
  };

  useEffect(() => {
    document.title = "Patti Sewa - User Manual";
  }, []);

  useEffect(() => {
    let numExpandedSections = 0;
    const numTotalSections = Object.keys(sectionsExpanded).length;

    for (const key in sectionsExpanded) {
      numExpandedSections += sectionsExpanded[key];
    }

    setIsExpandAllEnabled(numExpandedSections < numTotalSections);
    setIsCollapseAllEnabled(numExpandedSections > 0);
  }, [sectionsExpanded]);

  return (
    <Box className={classes.pageContainer}>
      <Container className={classes.container}>
        <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
          <Typography variant="h3" className={classes.heading} style={{ fontWeight: "bold", textAlign: "center" }}>
            User Manual
          </Typography>
        </Box>

        <Box width="100%" display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" mb={3}>
          <Box mr={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleExpandAllButtonClick}
              disabled={!isExpandAllEnabled}
            >
              Expand All
            </Button>
          </Box>

          <Box mr={2}>
            <Button
              variant="contained"
              color="error"
              onClick={handleCollapseAllButtonClick}
              disabled={!isCollapseAllEnabled}
            >
              Collapse All
            </Button>
          </Box>
        </Box>

        <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.loggingIn}
            onChange={(event, expanded) => setExpandedForSection("loggingIn", expanded)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Logging In</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>All features of the Application are accessible only after authentication.</Typography>
              <Typography>
                In order to Login, head to <Link to="https://pattisewa.org/auth/login">the login page</Link>, enter your
                credentials, and hit the LOGIN button.
              </Typography>

              <img src="/static/images/user-manual/login-page.jpg" className={classes.contentImage} />
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.home}
            onChange={(event, expanded) => setExpandedForSection("home", expanded)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Home</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>The application has 3 main entities:</Typography>
              <Typography> 1. Points: the various Patti points of Nishkam Sewa.</Typography>
              <Typography> 2. Patients</Typography>
              <Typography> 3. Logs (Patient Logs): a log of when a patient has visited a point.</Typography>
              <br />
              <Typography>
                <strong>Note: </strong>
                The User Manual incorporates sample patients, such as "Apple," "Bottle," and "Mouse," to demonstrate the
                application's various features.
              </Typography>

              <img src="/static/images/user-manual/home-page.jpg" className={classes.contentImage} />

              <Typography>
                On the Home Screen, the first dropdown on the left allows the selection of the Point whose Logs are
                displayed below.
              </Typography>
              <Typography>
                The ADD LOG button allows the addition of a Patient Log at the currently selected Point, clicking it
                will navigate the application to the Add Log Page.
              </Typography>
              <Typography>
                The ADD PATIENT button allows the addition of a new Patient. On pressing this button, you will be
                navigated to the Add Patient Page.
              </Typography>
              <Typography>
                The Refresh Icon-button to the right of the ADD PATIENT button allows you to refresh the Logs shown
                below.
              </Typography>
              <Typography>
                Below the three buttons, we can see the recent Patient Logs of the currently selected Point. Clicking on
                a Log will show its details.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.addPatientLog}
            onChange={(event, expanded) => setExpandedForSection("addPatientLog", expanded)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Add Patient Log</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>
                This Page is also accessible by clicking the Plus Icon-button on the Navigation Bar at the bottom of the
                Screen.
              </Typography>
              <Typography>Here we can add a new Log for a particular Patient.</Typography>

              <img src="/static/images/user-manual/add-patient-log-page.jpg" className={classes.contentImage} />

              <Typography>
                In order to add one or more images to the Patient Log, use the Plus Icon-button right above the CREATE
                LOG Button at the bottom of the Page. Each added image must have a text corresponding to it.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.addPatient}
            onChange={(event, expanded) => setExpandedForSection("addPatient", expanded)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Add Patient</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>This Page allows us to insert a new Patient into the application's database.</Typography>

              <img src="/static/images/user-manual/add-patient-page.jpg" className={classes.contentImage} />
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.patientLogDetail}
            onChange={(event, expanded) => setExpandedForSection("patientLogDetail", expanded)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Patient Log Detail</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>
                This Page displays the details of a Patient Log and allows us to edit the fields similar to the Add
                Patient Log Page.
              </Typography>

              <img src="/static/images/user-manual/patient-log-detail-page.jpg" className={classes.contentImage} />
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.patientDetail}
            onChange={(event, expanded) => setExpandedForSection("patientDetail", expanded)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Patient Detail</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>
                This Page displays the details of a Patient and allows us to edit them similar to the Add Patient Page.
              </Typography>

              <img src="/static/images/user-manual/patient-detail-page.jpg" className={classes.contentImage} />

              <Typography>
                At the top, another Navigation Bar is available that allows us to move between the Patient’s details,
                logs, documents, as well as add a log or document for this Patient.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.searchPatients}
            onChange={(event, expanded) => setExpandedForSection("searchPatients", expanded)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Search Patients</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>
                This Page can be accessed by clicking the second icon on the Navigation Bar at the bottom of the Screen.
              </Typography>
              <Typography>
                This Page allows you to search among all the Patients in the application. A Patient’s name can be used
                to narrow down the search. Empty searches are allowed.
              </Typography>

              <img
                src="/static/images/user-manual/search-patients-page.jpg"
                className={classes.contentImageLandscape}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.searchPatientLogs}
            onChange={(event, expanded) => setExpandedForSection("searchPatientLogs", expanded)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Search Patient Logs</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>
                This Page can be accessed by clicking the fourth icon on the Navigation Bar at the bottom of the Screen.
              </Typography>
              <Typography>
                We can search for various Patient Logs by selecting the appropriate Search Fields and clicking the
                Search button. All Search Fields are optional.
              </Typography>

              <img
                src="/static/images/user-manual/search-patient-logs-page.jpg"
                className={classes.contentImageLandscape}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{ boxShadow: 3 }}
            className={classes.accordion}
            expanded={sectionsExpanded.applicationSettings}
            onChange={(event, expanded) => setExpandedForSection("applicationSettings", expanded)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">Application Settings</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography>
                This Page can be accessed by clicking the last icon on the Navigation Bar at the Bottom of the Screen.
              </Typography>

              <img src="/static/images/user-manual/application-settings-page.jpg" className={classes.contentImage} />

              <Typography>The Settings Page shows the details of the currently logged in user.</Typography>
              <Typography>
                Pressing the RESET PASSWORD button will open a new page that allows the User to reset their password via
                email.
              </Typography>
              <Typography>
                Pressing the LOGOUT button will end the current user’s session and take you back to the Login Page.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#faf9f2, #faf3f0, #cdd4cc)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 50,
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 25,
    marginBottom: 100,
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  contentImage: {
    maxWidth: "50%",
    border: "1px solid #000",
    marginTop: 10,
    marginBottom: 10,
  },
  contentImageLandscape: {
    maxWidth: "100%",
    border: "1px solid #000",
    marginTop: 10,
    marginBottom: 10,
  },
  accordion: {
    width: "100%",
  },
  accordionDetails: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));
