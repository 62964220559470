import { useState, useContext } from "react";
import { TextField, Button, Link, Box, Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Navigate } from "react-router-dom";

import { saveToLocalStorage } from "../localStorage/localStorage";
import { appFullName } from "../config/appConfig";
import userContext from "../context/userContext";
import apiServicesClient from "../api/apiServices";

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user } = useContext(userContext);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginButtonOnClick = async (event) => {
    event.preventDefault();
    setLoading(true);

    console.log("requesting...");
    const response = await apiServicesClient.user.login(email, password);
    console.log(response);

    if (response.success) {
      saveToLocalStorage("user", response.result.user);
      saveToLocalStorage("tokens", response.result.tokens);
      window.dispatchEvent(new Event("userChanged"));
      navigate("/");
      return;
    }

    if (response.error?.message) {
      toast(response.error.message);
    }

    if (response.error.validationErrors) {
      for (const errorField in response.error.validationErrors) {
        response.error.validationErrors[errorField].forEach((validationError) => {
          // console.log(validationError);
          toast(validationError);
        });
      }
    } else if (response.error.message) {
      toast(response.error.message);
    }

    setLoading(false);
  };

  if (user) {
    return <Navigate to="/auth/double-auth-attempt" />;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
        <Typography variant="h3" className={classes.heading} style={{ fontWeight: "bold", textAlign: "center" }}>
          {appFullName}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="/logo512.png"
          style={{
            maxHeight: "10vh",
          }}
        />
      </Box>

      <Box display="flex" justifyContent="center" mt={3} mb={0}>
        <TextField
          className={classes.inputTextField}
          type="email"
          label="Email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </Box>
      <Box display="flex" justifyContent="center" mb={0}>
        <TextField
          className={classes.inputTextField}
          type="password"
          label="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </Box>

      <Box display="flex" justifyContent="center" mb={3}>
        <Button variant="contained" color="primary" onClick={loginButtonOnClick} disabled={loading}>
          {loading ? <BeatLoader color="white" loading={true} size={10} /> : "Login"}
        </Button>
      </Box>

      <Box display="flex" justifyContent="center" mb={0}>
        <Link href="/auth/request-reset-password">Forgot Password?</Link>
      </Box>
      <Box display="flex" justifyContent="center" mb={0}>
        <Link href="/auth/register">Registering a new account?</Link>
      </Box>
      <Box display="flex" justifyContent="center" mb={2}>
        <Link href="/user-manual">User Manual</Link>
      </Box>
    </Container>
  );
};

export default Login;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  inputTextField: {
    width: 300,
    "&&": {
      marginBottom: 25,
    },
  },
  alternateLinkText: {
    marginTop: 20,
  },
}));
