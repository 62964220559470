const patientDeleteAllowedTimeInHours = 24;
const patientLogDeleteAllowedTimeInHours = 24;
const patientLogEditAllowedTimeInHours = 48;
const patientDocumentDeleteAllowedTimeInHours = 24;
const appName = "Patti Sewa";
const appFullName = "Patti Sewa Patient Database";

export {
  appName,
  appFullName,
  patientDeleteAllowedTimeInHours,
  patientLogDeleteAllowedTimeInHours,
  patientLogEditAllowedTimeInHours,
  patientDocumentDeleteAllowedTimeInHours,
};
