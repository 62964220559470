import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import DownloadIcon from "@mui/icons-material/Download";

const sampleReport1Data = {
  patientName: "The data in this report is not that of a real patient. ",
  logs: [
    {
      date: "18 August 2023",
      location: "Sai Baba Mandir",
      notes: "Bandaged with Soframycin",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/1.jpg",
          notes: "Pre-treatment wound image",
        },
      ],
    },
    {
      date: "19 August 2023",
      location: "Sai Baba Mandir",
      notes: "Bandaged with Soframycin",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/2.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "20 August 2023",
      location: "Sai Baba Mandir",
      notes: "Bandaged with Soframycin",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/3.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "21 August 2023",
      location: "Sai Baba Mandir",
      notes: "Bandaged with Soframycin",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/4.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "22 August 2023",
      location: "Sai Baba Mandir",
      notes: "Bandaged with Soframycin",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/5.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "23 August 2023",
      location: "Sai Baba Mandir",
      notes: "Bandaged with Soframycin",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/6.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "24 August 2023",
      location: "Sai Baba Mandir",
      notes: "Bandaged with Soframycin",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/7.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "25 August 2023",
      location: "Sai Baba Mandir",
      notes: "Bandaged with Soframycin",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/8.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "26 August 2023",
      location: "Sai Baba Mandir",
      notes: "Bandaged with Soframycin",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/9.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "27 August 2023",
      location: "Sai Baba Mandir",
      notes: "Bandaged with Soframycin",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/10.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "28 August 2023",
      location: "Sai Baba Mandir",
      notes: "Bandaged with Soframycin",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/11.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "29 August 2023",
      location: "Sai Baba Mandir",
      notes: "Bandaging no longer required",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/12.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "30 August 2023",
      location: "Sai Baba Mandir",
      notes: "No treatment",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/13.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "31 August 2023",
      location: "Sai Baba Mandir",
      notes: "No treatment",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/14.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "01 September 2023",
      location: "Sai Baba Mandir",
      notes: "No treatment",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/15.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "02 September 2023",
      location: "Sai Baba Mandir",
      notes: "No treatment",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/16.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "03 September 2023",
      location: "Sai Baba Mandir",
      notes: "No treatment",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/17.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "04 September 2023",
      location: "Sai Baba Mandir",
      notes: "No treatment",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/18.jpg",
          notes: "",
        },
      ],
    },
    {
      date: "05 September 2023",
      location: "Sai Baba Mandir",
      notes: "No treatment",
      prescription: "-",
      images: [
        {
          uri: "/static/images/sample-report-1/19.jpg",
          notes: "",
        },
      ],
    },
  ],
};

export default function SampleReport1() {
  const classes = useStyles();

  const handleDownloadButtonClick = () => {
    window.open("/static/pdfs/sample-report-1.pdf", "_blank");
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5" style={{ fontWeight: "bold", textAlign: "center" }}>
          Sample Report 1
        </Typography>

        <IconButton color="warning" onClick={handleDownloadButtonClick}>
          <DownloadIcon />
        </IconButton>
      </Box>

      <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" mb={2}>
        <Typography
          sx={{
            width: "100%",
            textAlign: "left",
            marginBottom: 2,
          }}
        >
          {sampleReport1Data.patientName}
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {sampleReport1Data.logs.map((log, logIndex) => (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#eeeeee",
                paddingTop: 1,
                paddingBottom: 1,
                borderRadius: 2,
                marginBottom: 1,
              }}
              key={logIndex}
            >
              <Box
                sx={{
                  width: "95%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    width: "95%",
                  }}
                >
                  {log.date}
                </Typography>
                <Typography
                  sx={{
                    width: "95%",
                    textAlign: "right",
                  }}
                >
                  {log.location}
                </Typography>
              </Box>

              <Typography
                sx={{
                  width: "95%",
                }}
              >
                Notes: {log.notes}
              </Typography>
              <Typography
                sx={{
                  width: "95%",
                }}
              >
                Prescription: {log.prescription}
              </Typography>

              <Typography
                sx={{
                  width: "95%",
                  marginTop: 0,
                }}
              >
                Images:
              </Typography>

              {log.images.map((image, imageIndex) => (
                <Box
                  sx={{
                    width: "90%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    src={image.uri}
                    style={{
                      maxWidth: "49%",
                    }}
                  />
                  <Typography
                    sx={{
                      maxWidth: "49%",
                    }}
                  >
                    {image.notes}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    paddingBottom: 20,
    marginBottom: 50,
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  inputTextField: {
    width: 300,
    "&&": {
      marginBottom: 25,
    },
  },
  alternateLinkText: {
    marginTop: 20,
  },
}));
