import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import {
  Home as HomeIcon,
  PersonSearch as PersonSearchIcon,
  ManageSearch as ManageSearchIcon,
  Add as AddIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";

import { getPathnameWithoutParams } from "../utils/jsUtils";

const locationsToHideBottomNavigatorOn = [
  "/auth/perform-setup",
  "/auth/login",
  "/auth/register",
  "/about",
  "/user-manual",
  "/reports/sample-1",
  "/auth/request-reset-password",
];
const bottomNavigationMainLocations = [
  "/",
  "/patients/search",
  "/patient-logs/add",
  "/patient-logs/search",
  "/settings",
];

export default function MuiBottomNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  const [showBottomNavigation, setShowBottomNavigation] = useState(false);
  const [value, setValue] = useState(location.pathname);

  const handleBottomNavigationChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  useEffect(() => {
    const pathnameWithoutParams = getPathnameWithoutParams(location.pathname);

    setShowBottomNavigation(!locationsToHideBottomNavigatorOn.includes(pathnameWithoutParams));
    if (bottomNavigationMainLocations.includes(pathnameWithoutParams)) {
      setValue(pathnameWithoutParams);
    }
  }, [location]);

  if (!showBottomNavigation) {
    return null;
  }

  return (
    <BottomNavigation
      sx={{ width: "100%", position: "fixed", bottom: 0, marginTop: 100 }}
      value={value}
      onChange={handleBottomNavigationChange}
    >
      <BottomNavigationAction value="/" icon={<HomeIcon />} />
      <BottomNavigationAction value="/patients/search" icon={<PersonSearchIcon />} />
      <BottomNavigationAction value="/patient-logs/add" icon={<AddIcon />} />
      <BottomNavigationAction value="/patient-logs/search" icon={<ManageSearchIcon />} />
      <BottomNavigationAction value="/settings" icon={<SettingsIcon />} />
    </BottomNavigation>
  );
}
