import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import { appFullName } from "../config/appConfig";

export default function About() {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.container}>
      <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" mb={2}>
        <Typography variant="h3" className={classes.heading} style={{ fontWeight: "bold", textAlign: "center" }}>
          {appFullName}
        </Typography>
      </Box>

      <Box width="100%" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" mb={2}>
        <Typography variant="p" style={{ textAlign: "left" }}>
          The Patti Sewa Patient Database Application is a purpose-driven platform designed to facilitate the seamless
          management and documentation of patient care activities at the various Patti Points.
        </Typography>

        <br />
        <br />

        <Typography variant="p" style={{ width: "100%", fontWeight: "bold", textAlign: "left" }}>
          Key Features of the Application:
        </Typography>

        <List sx={{ listStyle: "decimal" }}>
          <ListItem sx={{ display: "list-item", pl: 0, ml: 2 }}>
            <Typography>
              <strong>Point Management:</strong> View and manage various Sewa Patti points, enabling focused and
              location-specific patient care.
            </Typography>
          </ListItem>

          <ListItem sx={{ display: "list-item", pl: 1, ml: 2 }}>
            <Typography>
              <strong>Patient Records:</strong> Maintain comprehensive profiles for each patient, including their
              personal information, medical history, and associated logs.
            </Typography>
          </ListItem>

          <ListItem sx={{ display: "list-item", pl: 1, ml: 2 }}>
            <Typography>
              <strong>Patient Logs:</strong> Track patient visits with detailed logs, including visit dates, care
              activities, and accompanying documents or images.
            </Typography>
          </ListItem>

          <ListItem sx={{ display: "list-item", pl: 1, ml: 2 }}>
            <Typography>
              <strong>Search Functionality:</strong> Quickly locate patients and logs using powerful search tools.
            </Typography>
          </ListItem>

          <ListItem sx={{ display: "list-item", pl: 1, ml: 2 }}>
            <Typography>
              <strong>User Management:</strong> Ensure data security and personalized experiences with user
              authentication, profile management, and secure password resets.
            </Typography>
          </ListItem>
        </List>

        <Typography variant="p" style={{ textAlign: "justify" }}>
          The Application bridges the gap between traditional record-keeping and modern technology, simplifying
          healthcare management. Whether you're adding a new patient, reviewing their history, or managing the care
          provided at a specific point, the app makes the process as effortless and effective as possible.
        </Typography>
      </Box>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
    paddingBottom: 20,
    marginBottom: 50,
  },
  heading: {
    background: "linear-gradient(45deg, #FF1744 30%, #2962FF 90%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontWeight: "bold",
    textAlign: "center",
  },
  inputTextField: {
    width: 300,
    "&&": {
      marginBottom: 25,
    },
  },
  alternateLinkText: {
    marginTop: 20,
  },
}));
